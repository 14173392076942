import React from 'react'

function    Loader() {
  return (
    <div className='loader'>
        <div className="loading"></div>
    </div>
  )
}

export default Loader